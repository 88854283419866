@import 'base.sass';

html {
    body {
        .banner,
        .cajas .caja {
            position: relative;
            width: 100%;
            height: 175px;
            @media (min-width: $breakpoint1) {
                height: 390px; }
            .titulo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: auto;
                padding: 10px 20px;
                @media (min-width: $breakpoint1) {
                    padding: 30px 60px; }
                * {
                    display: block;
                    width: auto;
                    color: $color1;
                    font-family: Harman;
                    font-size: rem(30);
                    line-height: rem(36.3);
                    text-align: center;
                    @media (min-width: $breakpoint1) {
                        font-size: rem(50);
                        line-height: rem(50); } }
                .background {
                    background-image: url('../../images/Banner-title.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    transform: rotateZ(-3.44deg); }
                .background.oscuro {
                    background-image: url('../../images/Banner-title-oscuro.svg'); } }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center; } }

        .cajas {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: repeat(3, 190px);
            @media (min-width: $breakpoint2) {
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: 630px; }
            .caja {
                width: 100% !important;
                height: 100% !important;
                overflow: hidden;
                a {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%; } } }
        .cajas.cajas4 {
            grid-template-rows: repeat(4, 190px);
            @media (min-width: $breakpoint2) {
                grid-template-rows: 630px 630px; }
            .caja {
                @media (min-width: $breakpoint2) {
                    &:nth-child(4n) {
                        grid-column: 1/4; } } } }

        #contenido {
            position: relative;
            z-index: 10;
            .cuerpo {
                width: 100%;
                max-width: $cuerpo2;
                margin: 0 auto; } }
        #contenido.estrecho {
            .cuerpo {
                max-width: $cuerpo3; } }
        #paginacion {
            margin: 30px auto;
            text-align: center;
            ul {
                border: none;
                list-style: none;
                margin: 0 auto;
                display: inline-block;
                white-space: nowrap;
                li {
                    display: inline-block;
                    border: none;
                    padding: 20px;
                    color: $color2;
                    font-size: rem(16);
                    line-height: rem(19.5);
                    font-weight: bold; }
                .prev,
                .next {
                    a {
                        padding: 0;
                        color: $color6;
                        &:hover {
                            background: none;
                            text-decoration: underline; } } }
                .prev {
                    border-right: 1px solid $color6; }
                .next {
                    border-left: 1px solid $color6; } } } }
    .category, {
        #contenido {
            position: relative;
            z-index: 10;
            width: 100%;
            max-width: $cuerpo2;
            margin: 0 auto;
            padding: 20px;
            #categorias {
                @include ocultascroll;
                padding: 45px 10px;
                display: grid;
                grid-template-columns: repeat(5, auto);
                justify-content: center;
                a {
                    display: block;
                    width: max-content;
                    margin: 10px;
                    padding: 14px 64px;
                    border: 2px solid $color5;
                    border-radius: 50px;
                    color: $color5;
                    font-size: rem(18);
                    line-height: rem(25.18);
                    font-weight: bold;
                    text-decoration: none;
                    text-transform: uppercase; } }
            .posts {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: repeat(6, 1fr);
                column-gap: 8px;
                grid-row-gap: 15px;
                @media (min-width: $breakpoint1) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: repeat(4, 380px);
                    column-gap: 24px;
                    grid-row-gap: 44px; }
                .post {
                    a {
                        position: relative;
                        display: block;
                        width: 100%;
                        height: 100%;
                        text-decoration: none;
                        .imagen_destacada {
                            width: 100%;
                            height: calc(80vw / 2);
                            @media (min-width: $breakpoint1) {
                                width: 100%;
                                height: 100%; }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                filter: brightness(0.9); } }
                        h2 {
                            color: $color3;
                            font-size: rem(20px);
                            line-height: rem(19.7);
                            @media (min-width: $breakpoint1) {
                                position: absolute;
                                bottom: 15px;
                                left: 15px;
                                width: calc(100% - 30px);
                                color: $color1;
                                font-size: rem(20px);
                                line-height: rem(19.7); } } } } } } }
    .manuscrito {
        font-family: Dear Joe !important; }
    .adorno {
        @media (min-width: $breakpoint1) {
            position: relative;
            &:before {
                content: url('../../images/comillas1.svg');
                position: absolute;
                top: -55px;
                left: -70px; }
            &:after {
                content: url('../../images/comillas2.svg');
                position: absolute;
                bottom: -70px;
                right: -70px; } } }
    #posts_relacionados {
        background: $color1;
        .hoja3 {
            background-image: url('../../images/hoja3.svg');
            background-repeat: no-repeat;
            background-position: top left; }
        .hoja4 {
            background-image: url('../../images/hoja4.svg');
            background-repeat: no-repeat;
            background-position: bottom right; }
        #contenido {
            width: 100%;
            max-width: $cuerpo2;
            padding-top: 60px;
            padding-bottom: 60px;
            margin: 0px auto;
            color: $color3;
            text-align: center;
            @media (min-width: $breakpoint1) {
                padding-top: 100px; }
            h3 {
                position: relative;
                display: inline-block;
                margin: 30px auto 60px;
                font-family: Harman;
                font-size: rem(30);
                line-height: rem(36);
                text-align: center;
                @media (min-width: $breakpoint1) {
                    font-size: rem(60);
                    line-height: rem(59); } }
            h4 {
                font-family: Harman;
                font-size: rem(30);
                line-height: rem(30);
                text-align: center; }
            .boton {
                text-decoration: none;
                text-transform: uppercase;
                border-radius: 50px;
                border: 2px solid $color7;
                color: $color7;
                padding: 14px 38px;
                font-weight: bold;
                font-size: rem(18);
                line-height: rem(25.2);
                display: block;
                width: max-content;
                margin: 50px auto; }
            .posts {
                padding: 25px;
                display: grid;
                grid-template-columns: repeat(3, 214px);
                grid-template-rows: 210px;
                column-gap: 15px;
                grid-row-gap: 25px;
                @media (min-width: $breakpoint1) {
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: 380px;
                    column-gap: 24px;
                    grid-row-gap: 44px; }
                .post {
                    text-align: left;
                    a {
                        position: relative;
                        display: block;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                        text-decoration: none;
                        .imagen_destacada {
                            width: 100%;
                            height: 100vw;
                            @media (min-width: $breakpoint1) {
                                width: 100%;
                                height: 100%; }
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                object-position: center;
                                filter: brightness(0.9); } }
                        h2 {
                            position: absolute;
                            bottom: 15px;
                            left: 15px;
                            width: calc(100% - 30px);
                            color: $color1;
                            font-size: rem(20px);
                            line-height: rem(19.7); } } } } } }
    .single {
        position: relative;
        z-index: 10;
        #navegacion,
        #blog {
            width: 100%;
            max-width: $cuerpo2;
            margin: 0 auto; }
        #navegacion {
            padding: 18px 25px;
            text-align: right;
            svg {
                transform: rotate(90deg);
                path {
                    stroke: $color7; } }
            a {
                color: $color7;
                font-size: rem(18px);
                line-height: rem(25.18);
                font-weight: bold;
                text-transform: uppercase;
                text-decoration: none; } }
        #blog {
            @media (min-width: $breakpoint2) {
                display: grid;
                grid-template-columns: auto 320px;
 }                // column-gap: 60px
            .post {
                padding: 0px;
                .contenido {
                    @media (min-width: $breakpoint2) {
                        padding-right: 60px; } }
                .titulo {
                    padding: 20px 25px;
                    @media (min-width: $breakpoint2) {
                        padding: 20px 0px; }
                    h1 {
                        margin: 10px auto;
                        padding: 0; }
                    .fecha_autor {
                        color: $color5;
                        font-size: rem(18);
                        line-height: rem(22);
                        .autor {
                            font-weight: bold; } } }
                h1,
                h2,
                h3,
                h4,
                p {
                    padding: 20px 25px; }
                h1 {
                    color: $color3;
                    font-size: rem(25);
                    line-height: rem(30.5); }
                h2,
                h3,
                h4 {
                    color: $color3;
                    font-size: rem(20);
                    line-height: rem(24.42); }
                .imagen_destacada,
                img {
                    width: 100%;
                    height: auto;
                    margin: 20px auto; }
                .info {
                    color: $color7;
                    font-size: rem(25);
                    line-height: rem(30.3);
                    width: 100%;
                    margin-bottom: 40px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    @media (min-width: $breakpoint1) {
                        flex-direction: row;
                        justify-content: space-around;
                        align-items: center; }
                    span {
                        display: block;
                        margin: 10px 20px;
                        svg {
                            height: rem(25); } } }
                .ingredientes,
                .preparacion {
                    h3 {
                        margin-bottom: 25px;
                        padding: 0;
                        color: $color7;
                        font-size: rem(30);
                        line-height: rem(36.6); }
                    ul {
                        list-style: disc;
                        margin-left: 20px;
                        li {
                            margin-bottom: 10px;
                            color: $color3;
                            font-size: rem(18);
                            line-height: rem(28.7); } } }
                .ingredientes {
                    background-color: rgba(141, 177, 134, 0.2);
                    .hoja1 {
                        background-image: url('../../images/hoja1.svg');
                        background-repeat: no-repeat;
                        background-position: 80% 100%; }
                    .hoja2 {
                        padding: 30px 30px 30px 60px;
                        background-image: url('../../images/hoja2.svg');
                        background-repeat: no-repeat;
                        background-position: top right; } }
                .preparacion {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-row-gap: 20px;
                    margin: 30px auto;
                    @media (min-width: $breakpoint1) {
                        grid-template-columns: 500px auto;
                        grid-template-rows: 1fr;
                        column-gap: 20px;
                        grid-row-gap: 0px; }
                    .col-1 {
                        padding: 30px 30px 30px 60px;
                        background-image: url('../../images/trama1.png');
                        background-color: $color1;
                        background-size: auto;
                        background-position: center; }
                    .col-2 {
                        padding: 20px;
                        @media (min-width: $breakpoint1) {
                            padding: 0px; } }
                    img {
                        margin: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center; } } }
            .sidebar-blog {
                display: none;
                @media (min-width: $breakpoint2) {
                    display: block;
                    aside {
                        border-left: 1px solid $color6;
                        padding: 0px 23px; }
                    .buscar {
                        .wp-block-search__inside-wrapper {
                            background: $color1;
                            border: 1px solid $color6;
                            border-radius: 50px;
                            padding: 0;
                            input,
                            button {
                                border: none;
                                background: transparent;
                                color: $color2;
                                font-size: rem(14);
                                line-height: rem(20);
                                text-transform: uppercase; }
                            input {
                                margin: 0;
                                padding: 15px 5px 15px 20px; }
                            button {
                                padding: 15px 20px 15px 5px;
                                font-weight: bold; } } }
                    h3 {
                        margin: 0;
                        padding: 25px 0 25px auto;
                        color: $color5;
                        font-size: rem(20);
                        line-height: rem(24.42);
                        font-weight: bold; }
                    ul {
                        list-style: none;
                        li {
                            color: #898989;
                            padding: 10px 20px 10px 10px;
                            a {
                                color: #898989;
                                font-size: rem(18);
                                line-height: rem(22);
                                text-decoration: none; } } } } } } }
    #post_relacionados {
        .cuerpo {
            width: 100%;
            max-width: $cuerpo2;
            margin: 0 auto; } }
    .error404 {
        position: relative;
        z-index: 10;
        #contenido {
            padding: 130px 20px 170px 20px;
            background: $color8;
            .cuerpo {
                width: 100%;
                max-width: $cuerpo2;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                // align-items: last baseline
                align-items: center;
                h1 {
                    width: 100%;
                    text-align: center;
                    color: $color3;
                    font-family: Harman;
                    font-size: rem(100);
                    line-height: rem(100);
                    @media (min-width: $breakpoint1) {
                        width: 50%;
                        text-align: right;
                        min-width: max-content;
                        font-size: rem(300);
                        line-height: rem(295.5); } }

                h2 {
                    width: 100%;
                    color: $color3;
                    text-align: center;
                    font-family: Harman;
                    font-size: rem(40);
                    line-height: rem(40);
                    @media (min-width: $breakpoint1) {
                        width: 50%;
                        text-align: left;
                        font-size: rem(60);
                        line-height: rem(59); } }
                a {
                    display: block;
                    margin: 20px;
                    padding: 14px 57px;
                    border-radius: 50px;
                    background: $color4;
                    box-shadow: 0px 2px 30px rgba(252, 97, 97, 0.88);
                    color: $color1;
                    font-size: rem(18);
                    line-height: rem(25.18);
                    text-decoration: none; }
                svg {
                    width: 100%; } } } } }
html {
    body {
        img {
            max-width: 100%;
            height: auto; }
        .justificado {
            text-align: justify; }
        .CTA {
            background: $color1;
            padding: 45px 20px;
            a {
                @include boton; } }
        .button-link {
            padding: 45px 20px;
            a {
                @include boton;
                background-color: #094634;
                box-shadow: 0px 2px 30px rgba(9, 70, 52, 0.88); } }
        #contenido.full-width {
            .cuerpo {
                width: 100%;
                max-width: 100%;
                margin: 0; }
            .wp-block-media-text.hojas {
                @media (min-width: $breakpoint1) {
                    background-image: url('../../images/hoja6.svg');
                    background-position: left center;
                    background-size: contain;
                    background-repeat: no-repeat; } }
            .wp-block-media-text.formulario {
                .wp-block-media-text__content {
                    padding: 40px 10px; } }
            .wp-block-media-text {
                .wp-block-media-text__content {
                    max-width: 630px;
                    margin-right: auto;
                    padding-top: 40px;
                    padding-bottom: 40px;
                    h2 {
                        font-family: Harman;
                        font-size: rem(30);
                        line-height: rem(36.5);
                        margin-top: 10px;
                        @media (min-width: $breakpoint1) {
                            font-size: rem(60);
                            line-height: rem(59.4); } }
                    p {
                        line-height: rem(25); } } }
            .wp-block-media-text.has-media-on-the-right {
                .wp-block-media-text__content {
                    margin-right: 0;
                    margin-left: auto; } }
            .espaciado {
                .wp-block-media-text__content {
                    @media (min-width: $breakpoint1) {
                        padding: 170px 10px; } } }
            .wp-block-image {
                margin: 0;
                figcaption {
                    color: $color7;
                    font-weight: bold;
                    font-size: rem(24);
                    line-height: rem(29.3); }
                .aligncenter {
                    figcaption {
                        text-align: center; } } }
            .wp-block-SEO {
                width: 100%;
                max-width: $cuerpo2;
                margin: 0 auto;
                padding: 2.5rem 2.5rem;
                .default_block_title {
                        font-family: Dear Joe !important;
                        font-size: 1.3889rem;
                        line-height: 1.6667rem;
                        color: var(--color3);
                        text-decoration: none;
                        font-weight: bold;
                        margin-bottom: 20px;
                        margin-top: 30px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center; }
                .seo-content,
                .three_lines_seo_cotent {
                    text-align: center; }
                .seo-actions {
                    text-align: center;
                    padding-top: 0;
                    .seo-opener {
                        display: none; }
                    .seo-closer {
                        transform: rotate(180deg);
                        display: block; }
                    .seo-opener,
                    .seo-closer {
                        line-height: rem-calc(16); } }
                &.closed .seo-content {
                    display: none;
                    transition: all 1s ease-in-out;
                    text-align: center;
                    &.show {
                        display: block;
                        transition: all 1s ease-in-out; } }

                &.closed .seo-actions .seo-opener {
                    display: block;
                    transition: all 1s ease-in-out; }

                &.closed .seo-actions .seo-closer {
                    display: none;
                    transition: all 1s ease-in-out; } } }
        #contenido.full-width,
        #tienda {
            position: relative;
            z-index: 10;
            .contadores {
                background-image: url('../../images/fondo-contadores.svg');
                background-color: $color1;
                background-repeat: no-repeat;
                background-position: center;
                background-size: auto 120%;
                padding: 100px 0px 60px;
                @media (min-width: $breakpoint1) {
                    padding: 120px 0px 100px;
                    background-size: auto 100%;
                    background-color: inherit;
                    background-position: top right;
                    background-size: auto 130%; }
                .cuerpo {
                    width: 100%;
                    max-width: $cuerpo2;
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: $breakpoint1) {
                        flex-direction: row;
                        justify-content: space-around; }
                    .item {
                        margin: 20px;
                        width: max-content;
                        color: $color8;
                        font-size: rem(30);
                        line-height: rem(29.7);
                        font-weight: 700;
                        text-align: center;
                        span {
                            display: block;
                            margin: 10px auto;
                            color: $color1;
                            font-size: rem(50);
                            line-height: rem(49.5); } } } } }
        .banner-footer {
            position: relative;
            width: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .contenido {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 50px 20px 80px;
                width: 100%;
                min-height: 300px;
                @media (min-width: $breakpoint1) {
                    min-height: 500px; }
                .mensaje {
                    display: block;
                    width: 90%;
                    max-width: $cuerpo3;
                    color: $color1;
                    font-family: Harman;
                    font-weight: bold;
                    font-size: rem(30);
                    line-height: rem(36.3);
                    text-align: center;
                    text-shadow: 0px 4px 35px rgba(5, 5, 5, 0.65);
                    ul {
                        li {
                            font-family: DIN;
                            font-weight: normal;
                            font-size: rem(18);
                            line-height: rem(25);
                            text-align: left; } }

                    h6 {
                        font-size: rem(25);
                        line-height: rem(30); }
                    @media (min-width: $breakpoint1) {
                        font-size: rem(60);
                        line-height: rem(60);
                        h6 {
                            font-size: rem(30);
                            line-height: rem(30); } }
                    p {
                        margin-bottom: 0; } }

                .boton {
                    margin: 14px;
                    color: $color3;
                    font-weight: bold;
                    font-size: rem(18);
                    line-height: rem(25.2);
                    text-transform: uppercase;
                    text-decoration: none;
                    background: $color1;
                    border-radius: 50px;
                    padding: 14px 43px; } } }
        dl#faqs {
            dt {
                position: relative;
                display: block;
                padding: 15px 60px 15px 20px;
                margin-bottom: 2px;
                width: 100%;
                border: 1px solid $color7;
                background: $color7;
                color: $color1;
                font-weight: bold;
                font-size: rem(20);
                line-height: rem(24.4);
                cursor: pointer;
                &:after {
                    position: absolute;
                    right: 20px;
                    content: url('../../images/flecha-blanca-arriba.svg');
                    transition: all 300ms ease-in-out;
                    transform: rotate(180deg); } }
            dt.cerrar {
                border-color: {}
                background: $color1;
                color: $color2;
                &:after {
                    content: url('../../images/flecha-gris-abajo.svg'); } }
            dd {
                display: block;
                overflow: hidden;
                width: 100%;
                padding: 30px 20px;
                transition: all 300ms ease-in-out; }
            dd.cerrar {
                height: 0;
                padding: 0;
                opacity: 0; } }
        .wp-block-embed iframe {
            width: 100%;
            @media (min-width: $breakpoint1) {
                min-height: 500px; } }
        &.page-id-6591 {
            #contenido.full-width {
                .espaciado {
                    .wp-block-media-text__content {
                        @media (min-width: $breakpoint1) {
                            padding: 60px 10px; } } } } } } }

html {
    body {
        #contenido {
            position: relative;
            z-index: 10;
            .fondo-gallina {
                background-image: url('../../images/gallina.svg');
                background-repeat: no-repeat;
                background-position: -190px 565px;
                @media (min-width: $breakpoint1) {
                    background-position: bottom left; }
                .gb-inside-container {
                    background-image: url('../../images/hoja5.svg');
                    background-repeat: no-repeat;
                    background-position: right top;
                    @media (min-width: $breakpoint1) {
                        background-position: right 200px; } } }
            .imagen-responsive {
                img {
                    width: 100%;
                    margin: 0 auto;
                    display: block; }
                .responsive {
                    @media (min-width: $breakpoint1) {
                        display: none; } }
                .desktop {
                    display: none;
                    @media (min-width: $breakpoint1) {
                        display: block; } } }
            .testimonio {
                padding: 40px 20px 30px;
                h4 {
                    color: $color3;
                    font-size: rem(20);
                    line-height: rem(32); }
                h5 {
                    color: $color7;
                    font-size: rem(20);
                    line-height: rem(32); }
                h6 {
                    color: $color4;
                    font-size: rem(15);
                    line-height: rem(24); } }
            .contacto-titulo1 {
                margin: 0 auto 20px;
                font-size: rem(25);
                line-height: rem(30.5);
                @media (min-width: $breakpoint1) {
                    font-size: rem(36);
                    line-height: rem(44); } }
            .contacto-titulo2 {
                font-family: Harman;
                font-size: rem(30);
                line-height: rem(29.7);
                text-transform: uppercase;
                @media (min-width: $breakpoint1) {
                    font-size: rem(50);
                    line-height: rem(49.5); } }
            .contacto-titulo3 {
                margin-top: 0px;
                margin-bottom: 10px;
                font-family: Harman;
                font-size: rem(30);
                line-height: rem(36.3);
                text-transform: uppercase;
                @media (min-width: $breakpoint1) {
                    margin-top: 30px;
                    font-size: rem(60);
                    line-height: rem(59.4); } }
            .contacto-titulo4 {
                margin-top: 10px;
                margin-bottom: 40px;
                font-size: rem(20);
                line-height: rem(19.8);
                @media (min-width: $breakpoint1) {
                    font-size: rem(30);
                    line-height: rem(29.7); } }
            #trabaja_con_nosotros {
                .correcto {
                    text-align: center;
                    color: $color7; }
                .error {
                    text-align: center;
                    color: $color4; } }
            form {
                width: 100%;
                max-width: 490px;
                margin: 0 auto;
                padding: 10px;
                position: relative;
                input,
                select,
                textarea {
                    width: 100%;
                    margin: 5px auto;
                    padding: 13px 15px;
                    background: $color1;
                    border: 1px solid $color6;
                    border-radius: 5px;
                    color: $color2;
                    font-family: DIN;
                    font-weight: normal;
                    font-size: rem(18);
                    line-height: rem(21.8); }
                textarea {
                    margin-bottom: 25px; }
                input[type="checkbox"] {
                    width: auto; }
                input[type="submit"] {
                    @include boton;
                    margin: 35px auto; }
                a {
                    color: $color2; } }
            .nutricion-titulo1 {
                margin-top: 0;
                font-size: rem(25);
                line-height: rem(32.5);
                @media (min-width: $breakpoint1) {
                    font-size: rem(36);
                    line-height: rem(44); } }
            .nutricion-titulo2 {
                margin: 30px auto;
                color: $color4;
                font-family: Dear Joe;
                font-size: rem(30);
                line-height: rem(35.7);
                @media (min-width: $breakpoint1) {
                    font-size: rem(40);
                    line-height: rem(40); } }
            .nutricion-titulo3 {
                margin: 10px auto;
                font-family: Harman;
                font-size: rem(30);
                line-height: rem(36.3);
                text-transform: uppercase;
                @media (min-width: $breakpoint1) {
                    font-size: rem(60);
                    line-height: rem(60); } }
            .nutricion-subtitulo3 {
                font-size: rem(20);
                line-height: rem(19.8);
                @media (min-width: $breakpoint1) {
                    font-size: rem(30);
                    line-height: rem(29.7); } }
            .compromiso-subrayado {
                font-family: Harman;
                font-size: rem(30);
                line-height: rem(36.3);
                text-transform: uppercase;
                @media (min-width: $breakpoint1) {
                    font-size: rem(60);
                    line-height: rem(59.4); } }
            .carlotena-titulo1 {
                color: $color4;
                font-family: Dear Joe;
                font-size: rem(25);
                line-height: rem(25);
                @media (min-width: $breakpoint1) {
                    font-size: rem(40);
                    line-height: rem(40); } }
            .carlotena-titulo2 {
                font-family: Harman;
                font-size: rem(30);
                line-height: rem(36.3);
                text-transform: uppercase;
                margin-bottom: 10px auto 40px;
                @media (min-width: $breakpoint1) {
                    font-size: rem(60);
                    line-height: rem(59.4); } }
            .carlotena-titulo3 {
                font-family: Dear Joe;
                font-weight: normal;
                font-size: rem(30);
                line-height: rem(30);
                @media (min-width: $breakpoint1) {
                    font-size: rem(40);
                    line-height: rem(40); } }
            .carlotena-titulo4 {
                font-size: rem(25);
                line-height: rem(30.5);
                @media (min-width: $breakpoint1) {
                    font-size: rem(30);
                    line-height: rem(36.6); } }
            #calculadora_IMC {
                padding: 40px;
                h4 {
                    color: $color3; }
                ul {
                    margin-top: 50px;
                    list-style: none;
                    li {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        span {
                            flex-grow: 1; }
                        ins {
                            flex-grow: 100;
                            border-bottom: 1px solid var(--color6); } } } }
            .wp-block-columns.iconos {
                .wp-block-column {
                    @media (max-width: $breakpoint0) {
                        flex-basis: 50% !important; } } } } } }

html {
    .home {
        position: relative;
        z-index: 10;
        .wp-block-media-text {
            min-height: 100vh;
            .wp-block-media-text__media {
                mix-blend-mode: multiply; }
            .wp-block-media-text__content {
                @media (max-width: $breakpoint0) {
                    h2,p {
                        text-align: left; } } } }
        .wp-block-media-text.has-media-on-the-right {
            .wp-block-media-text__media {
                margin-left: -200px;
                mix-blend-mode: multiply;
                grid-row-start: 2;
                @media (min-width: $breakpoint0) {
                    grid-row-start: 1; } }
            .wp-block-media-text__content {
                grid-row-start: 1; } } } }
html {
    .page {
        position: relative;
        z-index: 10;
        background: url('../../images/fondo.jpg');
        background-position: center center;
        background-repeat: repeat;
        background-size: auto auto; }
    body {
        .carrusel {
            @include ocultascroll; }
        .carrusel::-webkit-scrollbar {
                display: none; } } }
#popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.8);
    .cierra_popup {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background: $color4;
        color: $color1;
        font-weight: bold;
        border: none;
        border-radius: 50px; }
    .mensaje {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: $breakpoint0;
        max-height: 100vh;
        @media (min-width: $breakpoint1) {
            max-width: $breakpoint1; }
        .banner_popup {
            display: block;
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                max-height: 100%; } }
        .personalizado {
            background: $color1;
            padding: 50px 20px; } }
    .mensaje.movil {
        display: block;
        @media (min-width: $breakpoint1) {
            display: none; } }
    .mensaje.escritorio {
        display: none;
        @media (min-width: $breakpoint1) {
            display: block; } } }
body:not(.archive):not(.single) #contenido .cuerpo {
    ul {
        padding-left: 20px;
        li {
            margin-bottom: 10px; } } }
@media (max-width: $cuerpo3) {
    body:not(.archive):not(.single) #contenido .cuerpo {
        padding: 0 20px; } }


.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both {
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; }

 @-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
    100% {
        -webkit-transform: translateY(-16px);
        transform: translateY(-16px); } } }
@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }

    100% {
        -webkit-transform: translateY(-16px);
        transform: translateY(-16px); } }
.slide-bottom {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both {
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; } }

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
    100% {
        -webkit-transform: translateY(16px);
        transform: translateY(16px); } }
@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0); }
    100% {
        -webkit-transform: translateY(16px);
        transform: translateY(16px); } }

